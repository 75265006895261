import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EnvioForm from "./components/EnvioForm";

export default function EnvioModal({
    open,
    cliente,
    selectedDireccionId,
    pieza,
    proveedor,
    onSubmit,
    onClose,
    medidas,
    onChangeMedidas,
}) {
    const [direccionEnvio, setDireccionEnvio] = useState(null);
    const [currentServicioEnvio, setCurrentServicioEnvio] = useState(null);

    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const [currentMedidas, setCurrentMedidas] = useState({});

    useEffect(() => {
        if(selectedDireccionId) {
            const direccion = cliente?.direcciones.find(d => d.id === selectedDireccionId);

            setDireccionEnvio(direccion);
        }

        if(pieza) {
            const servicio = presupuesto?.servicios_envio?.find(f => f.pieza_id === pieza.id);
            if(servicio) { setCurrentServicioEnvio(servicio); }
        }
    }, [pieza, proveedor, selectedDireccionId]);

    useEffect(() => {
        setCurrentMedidas(medidas);
    }, [medidas]);

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    }

    const getTipoPieza = (tipoPiezaId) => tipoPiezas.find(tp => tp.id === tipoPiezaId)

    const handleSubmitEnvio = (data) => {
        if(currentServicioEnvio) { data.id = currentServicioEnvio.id; }
        onSubmit(data);
    }

    return (
        (currentMedidas && Object.keys(currentMedidas).length > 0) && <Dialog
            open={open}
            onClose={handleCloseModal}
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="envio-form-title"
            aria-describedby="envio-form-description">
        <DialogTitle id="envio-form-title">
            <div className="w-100 d-flex align-items-center justify-content-between">
                <p className="fw-bold text-uppercase">Nuevo Envío</p>

                <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                </span>
            </div>
           
        </DialogTitle>
        <DialogContent>
            <div className="w-100 d-flex flex-column justify-content-between text-uppercase">
                <p className="mb-1">origen: <b>{pieza?.proveedor?.nombre}</b></p>
                 <div className="w-100 d-flex flex-column gap-2">
                    <small>PIEZA: <b>{getTipoPieza(parseInt(pieza.tipo_pieza_id))?.nombre}</b> </small>
                    <div className="d-flex gap-3">
                            <div className="d-flex flex-column w-100">
                                <label htmlFor="peso">Peso</label>
                                <div className="position-relative">
                                    <input 
                                        className="form-control" 
                                        defaultValue={medidas?.peso || 0}
                                        onChange={(e) => onChangeMedidas('peso', e.target.value)}
                                        min={0}
                                        name="peso"
                                        step="0.01"
                                        type="number" 
                                    />
                                    <span className="text-success icon">
                                        <b>KG</b>
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex flex-column w-100">
                                <label htmlFor="largo">Largo</label>
                                <div className="position-relative">
                                    <input 
                                        className="form-control" 
                                        defaultValue={medidas?.largo || 0}
                                        onChange={(e) => onChangeMedidas('largo', e.target.value)}
                                        min={0}
                                        name="largo"
                                        step="0.01"
                                        type="number" 
                                    />
                                    <span className="text-success icon">
                                        <b>CM</b>
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex flex-column w-100">
                                <label htmlFor="ancho">Ancho</label>
                                <div className="position-relative">
                                    <input 
                                        className="form-control" 
                                        defaultValue={medidas?.ancho || 0}
                                        onChange={(e) => onChangeMedidas('ancho', e.target.value)}
                                        min={0}
                                        name="ancho"
                                        step="0.01"
                                        type="number" 
                                    />
                                    <span className="text-success icon">
                                        <b>CM</b>
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex flex-column w-100">
                                <label htmlFor="alto">Alto</label>
                                <div className="position-relative">
                                    <input 
                                        className="form-control" 
                                        defaultValue={medidas?.alto || 0}
                                        onChange={(e) => onChangeMedidas('alto', e.target.value)}
                                        min={0}
                                        name="alto"
                                        step="0.01"
                                        type="number" 
                                    />
                                    <span className="text-success icon">
                                        <b>CM</b>
                                    </span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            
            <EnvioForm 
                cliente={cliente}
                pieza={pieza}
                medidas={medidas}
                proveedor={proveedor}
                direccionEnvio={direccionEnvio}
                onSubmit={handleSubmitEnvio}
            />
        </DialogContent>
    </Dialog>
    )
}